<template>
  <component
    :is="wrapperTag"
    :for="inputId"
    class="mb-16 block"
    :class="className"
  >
    <div
      v-if="label"
      :style="labelStyle"
      class="mb-8 flex items-center gap-8 text-14 font-medium text-neutral-900"
    >
      {{ label }}
      <slot name="labelIcon" />
    </div>
    <div
      dir="ltr"
      class="tw-state-normal flex items-center rounded-8 border border-solid px-8"
      :class="inputWrapperClass"
    >
      <div v-if="type === 'phone' && common.country" class="mr-8 flex">
        <template v-if="phoneWithCountrySelect">
          <WebSelect
            v-model="common.currentCountry"
            options-native-tooltip
            show-search
            name="countrySelect"
            size="sm"
            :options="countryOptions"
            :page-options="pageOptions"
            class="tw-country-select !mb-0 !min-w-[85px]"
            dropdown-content-class="!w-[250px]"
            selections-image-class="!w-16 !h-16 !basis-16"
            no-border
            no-focus-shadow
            hide-selected-option-check
            show-selected-value-as-label
            :disabled="disabled"
            @click.prevent
          />
        </template>
        <template v-else>
          <img
            class="mr-4"
            width="20"
            height="20"
            :src="flagUrl"
            :alt="common.country?.iso"
          />
          <span class="text-14 font-medium">{{ common.country?.iso }}</span>
        </template>
      </div>
      <WebIcon
        v-if="iconLeft"
        class="mr-8 text-neutral-500"
        :class="{
          'text-error-500': hasError && !disabled,
          'text-neutral-300': disabled
        }"
        :name="iconLeft"
        size="20"
      />
      <slot name="left" />
      <!-- Masked input -->
      <input
        v-if="isMaskInput"
        :id="inputId"
        ref="el"
        class="tw-state-normal-input block h-20 min-w-0 flex-1 border-none bg-transparent p-4 font-inter"
        :class="inputClass"
        :type="inputType"
        :name="name"
        :placeholder="maskedInputPlaceholder"
        :disabled="disabled"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :tabindex="tabIndex"
        :inputmode="inputmode"
        @blur="onBlur"
        @focus="emit('focus')"
        @keydown="onKeyDownInput"
        @keyup="onKeyUpInput"
        @keypress.enter="handleKeyPressEnter"
        @paste="emit('paste', $event)"
      />

      <textarea
        v-else-if="type === 'textarea'"
        :id="inputId"
        ref="el"
        v-model="inputValue"
        class="tw-state-normal-input block min-h-80 flex-1 border-none bg-transparent p-0 font-inter text-14"
        :class="{
          'tw-state-error-input': hasError,
          'tw-state-disabled-input': disabled
        }"
        :type="inputType"
        :tabindex="tabIndex"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :inputmode="inputmode"
        @paste="emit('paste', $event)"
      />

      <!-- Default input -->
      <input
        v-else
        :id="inputId"
        ref="el"
        v-model="inputValue"
        class="tw-state-normal-input block h-20 min-w-0 flex-1 border-none bg-transparent p-4 font-inter"
        :class="inputClass"
        :type="inputType"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :maxlength="charLimit"
        :inputmode="inputmode"
        v-bind="additionalValuesByType"
        :tabindex="tabIndex"
        @change="handleChange"
        @blur="onBlur"
        @focus="emit('focus')"
        @keydown="onKeyDownInput"
        @keyup="onKeyUpInput"
        @paste="emit('paste', $event)"
      />

      <img
        v-if="type === 'card' && common.currentMask?.icon"
        :src="cardUrl"
        :alt="common.currentMask?.name"
        class="h-24"
      />

      <slot name="right" />

      <svg
        v-if="inputValue && type === 'search'"
        version="1.1"
        role="presentation"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-labelledby="CloseCircle"
        width="24"
        height="24"
        data-test="CloseCircle"
        class="size-[20px] cursor-pointer text-neutral-300"
        @click="clearInput"
      >
        <path
          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
          fill="currentColor"
        />
      </svg>

      <WebIcon
        v-if="iconRight"
        class="ml-8 shrink-0 grow-0 basis-20 text-neutral-500"
        :class="{ 'text-error-500': hasError, 'text-neutral-300': disabled }"
        :name="iconRight"
        size="20"
      />
    </div>

    <span v-if="charLimit && !hideCharLimitText" class="float-right p-8 text-12 text-neutral-500">
      {{ remainingCharCount }}
    </span>

    <span
      v-if="hint || errorMessage || (meta.dirty && meta.valid && (successMessage || hint))"
      class="mt-8 block text-12"
      :class="{
        'text-neutral-500': !hasError && !disabled,
        'text-error-500': hasError && !disabled,
        'text-neutral-300': disabled
      }"
      data-test="hint"
    >
      <template v-if="errorMessage || (meta.dirty && meta.valid && (successMessage || hint))">
        {{ errorMessage || successMessage || hint }}
      </template>
      <template v-else>{{ hint }}</template>
    </span>
  </component>

  <slot name="after" />
</template>

<script setup lang="ts">
import { computed, reactive, ref, toRef, watch, watchEffect, nextTick, type PropType, type HTMLAttributes } from 'vue';
import { useField } from 'vee-validate';
import { purify } from '@shared/utils';
import WebSelect from '@shared/components/select/index.vue';
import WebIcon from '@shared/components/icon/index.vue';
import { getAssetFromCdn } from '@shared/utils/helpers';
import { useTranslate } from '@shared/composable/useTranslate';
import type { PageOptions } from '@shared/types/model';
import { usePhone, useNumeric, useNoMaskNumeric, useMask, useCard, countries, useAllowChars } from './utils';
import type { SelectOption } from '@shared/components/select/types';

const props = defineProps({
  type: {
    type: String,
    default: 'text',
    validator(value: string) {
      return ['text', 'password', 'email', 'number', 'search', 'phone', 'textarea', 'mask', 'card'].includes(value);
    }
  },
  className: { type: [String, Object], default: '' },
  name: { type: String, default: '', required: true },
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  tabIndex: { type: String, default: undefined },
  readonly: { type: Boolean, default: false },
  value: { type: String, default: '' },
  modelValue: { type: [String, Number, Boolean], default: '' },
  modelModifiers: { type: Object, default: () => ({}) },
  label: { type: String, default: '' },
  labelStyle: { type: Object, default: () => ({})},
  iconLeft: { type: String, default: '' },
  iconRight: { type: String, default: '' },
  error: { type: Boolean, default: false },
  hint: { type: String, default: '' },
  successMessage: { type: String, default: '' },
  labelHint: { type: String, default: '' },
  country: { type: String, default: 'TR' },
  phoneNumberAllowedCountries: { type: Array as PropType<string[]>, default: () => [] },
  min: { type: [Number, String], default: Number.MIN_SAFE_INTEGER },
  max: { type: [Number, String], default: Number.MAX_SAFE_INTEGER },
  step: { type: [Number, String], default: 10 },
  autocomplete: { type: String, default: 'off' },
  rules: { type: [String, Object], default: '' },
  mask: { type: [String, RegExp, Object], default: '' },
  noMask: { type: Boolean, default: false },
  wrapperTag: { type: String, default: 'label' },
  charLimit: { type: Number, default: undefined },
  hideCharLimitText: { type: Boolean, default: false },
  noBorder: { type: Boolean, default: false },
  size: { type: String, default: 'md' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  inputmode: { type: String as PropType<HTMLAttributes['inputmode']>, default: '' },
  emitOutsideOnEnter: { type: Boolean, default: false },
  phoneWithCountrySelect: { type: Boolean, default: false },
  locale: { type: String, default: '' },
  forceValue: { type: Boolean, default: false },
  forceMaskUpdate: { type: Boolean, default: false },
  allowChars: { type: [String, RegExp], default: '' },
  validateOnUpdate: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'keydown', 'keyup', 'error', 'cardCvvMaskChange', 'countryChange', 'paste']);

const { translate } = useTranslate();

const hasError = ref(props.error);
const el = ref();
const common = reactive({
  mask: ref(),
  masked: ref(),
  unmasked: ref(),
  unmaskedWithCode: ref(),
  country: ref(),
  currentCountry: ref(),
  currentMask: ref(),
  updateOptions: () => ({})
});

const nameRef = toRef(props, 'name');
const rulesRef = toRef(props, 'rules');
const val = `${props.modelValue || props.value}`;

const {
  meta,
  value: inputValue,
  errorMessage,
  setValue,
  handleBlur,
  handleChange,
  validate
} = useField<string | number>(nameRef, rulesRef, {
  initialValue: formatByModifiers(val ? purify(val) : val),
  validateOnValueUpdate: props.validateOnUpdate
});

const { checkNumberForRange } = useNoMaskNumeric({
  props,
  value: inputValue
});

if (props.allowChars) {
  useAllowChars({ props, value: inputValue });
}

const inputId = computed(() => {
  const name = props.name.toLowerCase().replace(/\s/g, '_');
  return `input_${name}`;
})

const remainingCharCount = computed(() => {
  if (!props.charLimit) return;

  const isLimitOver = `${inputValue.value}`.length > props.charLimit;
  const charCount = isLimitOver ? 0 : props.charLimit - `${inputValue.value}`.length;

  return charCount;
});

const flagUrl = computed(() => {
  if (props.type === 'phone' && common.country) {
    return getAssetFromCdn(`flags/${common.country?.iso}.svg`);
  }
  return '';
});

const cardUrl = computed(() => {
  return getAssetFromCdn(`cards/${common.currentMask?.icon}.svg`);
});

const isMaskInput = computed(() => {
  if (props.noMask && props.type === 'number') return false;
  return /(phone|number|mask|card)/.test(props.type);
});

const inputClass = computed(() => {
  return {
    [`tw-${props.size}`]: !!props.size,
    'tw-state-error-input': hasError.value,
    'tw-state-disabled-input': props.disabled
  };
});

const inputWrapperClass = computed(() => {
  return {
    'tw-state-error': hasError.value,
    'tw-state-disabled': props.disabled,
    'h-40': props.type !== 'textarea' && props.size != 'sm',
    'py-[5px]': props.size == 'sm',
    noBorder: props.noBorder,
    '!pl-0': isPhoneInputWithCountrySelect.value
  };
});

const inputType = computed(() => {
  if (isMaskInput.value) return 'text';
  return props.type;
});

const additionalValuesByType = computed(() => {
  if (props.type === 'number') {
    const list = ['min', 'max', 'step'].reduce((acc: any, key) => {
      acc[key] = (props as any)?.[key];
      return acc;
    }, {});
    return list;
  }
  return {};
});

const isPhoneInputWithCountrySelect = computed(() => props.type === 'phone' && props.phoneWithCountrySelect);

const maskedInputPlaceholder = computed(() => {
  if (isPhoneInputWithCountrySelect.value) return common.country?.code || props.placeholder;
  return props.placeholder
})

const inputStyle = computed(() => {
  return {
    focus: {
      borderColor: props.pageOptions?.colors?.theme?.[0]
    }
  }
})

const countryOptions = computed(() => {
  return countries.reduce((filteredCountries, current) => {
    const countryIso = current?.iso;
    const isAllowed = props.phoneNumberAllowedCountries?.length ? props.phoneNumberAllowedCountries.includes(countryIso) : true;
    if (isAllowed) {
      const item = {
        label: translate(`generate.country.${countryIso}`, props.locale),
        value: countryIso,
        image: getAssetFromCdn(`flags/${countryIso}.svg`)
      }
      filteredCountries.push(item);
    }
    return filteredCountries;
  }, [] as SelectOption[])
});

watch(
  () => common.currentCountry,
  (code) => {
    emit('countryChange', code);
  }
);

function emitErrorWithInputName(hasError:boolean, message = '') {
  const errMessage = props.name && hasError ? `${props.name} - ${message}` : message;
  emit('error', [hasError, errMessage]);
}

watch(
  () => errorMessage.value,
  (val) => {
    if (val) hasError.value = true;
    else hasError.value = false;
    emitErrorWithInputName(hasError.value, errorMessage.value);
  }
);

watch(
  () => props.error,
  (val) => {
    hasError.value = val;
    emitErrorWithInputName(hasError.value, errorMessage.value);
  }
);

watch(
  () => props.modelValue,
  (val) => {
    const forceUpdate = props.forceMaskUpdate || props.disabled;
    if (isMaskInput.value && forceUpdate && inputValue.value !== val) {
      inputValue.value = val + '';
      const el = common.mask?.el.input;
      if (el && el.value !== val) {
        el.value = val;
        common.mask.updateValue();
      }
    } else if (!isMaskInput.value && inputValue.value !== val) {
      inputValue.value = val + '';
    }
  }
);

function applyNewMask(mask: string) {
  common.mask.updateOptions({ mask });
}

watch(
  () => common.currentMask?.name,
  (val) => {
    if (val === 'American Express') emit('cardCvvMaskChange', '0000');
    else emit('cardCvvMaskChange', '000');
  }
);

watchEffect(() => {
  if (props.validateOnUpdate || !isMaskInput.value) emitOutside();
});

function onKeyDownInput(e: any) {
  emit('keydown', e);
}

function onKeyUpInput(e: any) {
  emit('keyup', e);
}

function clearInput() {
  inputValue.value = '';
}

function onBlur(...args: any) {
  handleBlur(...args);
  validate();
  checkNumberForRange();
  emitOutside();
  nextTick(() => emit('blur', args, prepareEmitValue()));
}

function setMask() {
  let maskRef = {};
  if (props.type === 'phone') {
    maskRef = usePhone({ el, props, value: inputValue.value });
  } else if (props.type === 'number' && !props.noMask) {
    maskRef = useNumeric({ el, props, value: inputValue.value });
  } else if (props.type === 'mask' && props.mask) {
    maskRef = useMask({ el, mask: props.mask as any, value: inputValue.value });
  } else if (props.type === 'card') {
    maskRef = useCard({ el, props, value: inputValue.value });
  }

  Object.keys(common).forEach((key) => {
    const hasKey = Object.prototype.hasOwnProperty.call(maskRef, key);
    if (hasKey) (common as any)[key] = (maskRef as any)[key];
  });
}

function formatByModifiers(value: string) {
  if (props.modelModifiers?.hex) {
    return '#' + value.replace('#', '').slice(0, 6);
  }

  if (props.modelModifiers?.url) {
    const protocol = value?.match(/^(https?:\/\/)/)?.[0] || 'http://';

    if (protocol === value) return value;

    return protocol + value.replace(/(https?:\/\/?)/g, '');
  }

  if (props.modelModifiers?.trim) {
    return value?.trim();
  }

  if (props.modelModifiers?.number) {
    return parseFloat(value);
  }

  return value;
}

function prepareEmitValue() {
  let value = isMaskInput.value ? common.unmaskedWithCode || common.unmasked : inputValue.value;

  if (props.type === 'number' && props.noMask && value === '') {
    value = props.min === Number.MIN_SAFE_INTEGER ? 0 : props.min;
  }

  const val = formatByModifiers(purify(value));

  return typeof val === 'string' ? val.trim() : val
}

function emitOutside() {
  // This mask control must be here because it triggers watchEffect. You can see the additional reason below
  let value = prepareEmitValue()

  nextTick(() => {
    let trimmedVal = '' as string | number;

    // This silly control must be here too because mask value cannot catch properly
    if (isMaskInput.value) {
      value = common.unmaskedWithCode || common.unmasked || '';
    }

    if (props.validateOnUpdate) {
      trimmedVal = formatByModifiers(purify(value));

      if (isMaskInput.value && inputValue.value !== value) setValue(value); // Update validation

      if (trimmedVal === props.modelValue && trimmedVal === props.value) return false;
      if (!props.modelValue && trimmedVal === props.value) return false;
    } else {
       // Update validation
      if (isMaskInput.value) setValue(value);

      trimmedVal = formatByModifiers(purify(value));
    }

    trimmedVal = typeof trimmedVal === 'string' ? trimmedVal.trim() : trimmedVal;
    emit('update:modelValue', trimmedVal);
  });
}

function handleKeyPressEnter(){
  if (props.emitOutsideOnEnter) emitOutside();
}

setMask();

defineExpose({ applyNewMask, el, validate });
</script>

<style lang="postcss" scoped>
.tw-state {

  &-normal {
    --input-focusBorderColor: v-bind('inputStyle.focus.borderColor');

    @apply border-transparent bg-white transition-shadow focus-within:shadow-none;

    &:focus-within:not(.tw-state-error) {
      border-color: var(--input-focusBorderColor, '#56C4D6')
    }

    &:not(.noBorder, .tw-state-error):not(:focus-within) {
      @apply border-neutral-200;
    }

    input[type='search']::-webkit-search {
      &-decoration,
      &-cancel-button,
      &-results-button,
      &-results-decoration {
        @apply hidden;
      }
    }
    &-input {
      @apply text-neutral-900 placeholder:text-neutral-500 border-none focus:border-0;

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &-error {
    @apply border-error-500 focus-within:border-error-500;

    &-input {
      @apply text-error-500;
    }
  }

  &-disabled {
    @apply border-neutral-200 bg-neutral-100 text-neutral-100;

    &-input {
      @apply text-neutral-300 placeholder:text-neutral-300;
    }
  }
}

.tw-sm {
  @apply py-[5px] text-12;
}

.tw-md {
  @apply py-[7px] text-14;
}

:deep(.tw-country-select) {
  .tw-select-normal {
    @apply !pr-0;
    .tw-selections__text {
      @apply text-14 text-neutral-900 font-medium;
    }
    .tw-select__arrow-up, .tw-select__arrow-down {
      @apply text-neutral-900;
    }
    &.tw-select-disabled {
      @apply border-transparent;
      .tw-selections__text, .tw-select__arrow-up, .tw-select__arrow-down {
        @apply text-neutral-300;
      }
    }
  }
}
</style>
